import { type AnalyticsUrlsRecord } from "../services/analytics";
import { dismissForever } from "../utils/dismiss";
import { createPopup } from "../utils/popup";
import { setButton, setElement } from "../utils/renders";
import { setStickyFooter } from "../utils/stickyFooter";

const configUrl =
  "https://cdn.givefreely.com/popup/gutenberg-footer/config.json";
const defaultConfig: Config | null = null;

const analyticsUrls: AnalyticsUrlsRecord = {
  onClose: "https://gvfr.ly/3TFPuQs",
  onOpen: "https://gvfr.ly/3IICL9y",
  onPermanentClose: "https://gvfr.ly/4903c52",
  onFailToLoadConfig: "https://gvfr.ly/3x4LNLz",
  onLoad: "https://gvfr.ly/499UCAN",
  onActivate: "",
  onInstallClick: "",
  onFooterOpen: "https://gvfr.ly/3V3cyt4",
  onFooterClose: "https://gvfr.ly/4dHppbT",
  onFooterPermanentClose: "https://gvfr.ly/3WLLyzJ",
  onFooterLoad: "https://gvfr.ly/44NMN3x",
  charityDirectoryLink: "https://gvfr.ly/3QQKeb4"
};

const analyticsEventId = "gutenberg";

createPopup(
  configUrl,
  defaultConfig,
  analyticsUrls,
  analyticsEventId,
  (config, container, analytics) => {
    const popup = document.getElementById("gf-popup");
    setElement("gf-header", config.wording.header);
    setElement("gf-footer", config.wording.footer);
    setElement(
      "gf-open-form-btn",
      config.wording.openFormBtn,
      config.questionnaireLink
    );
    setButton("gf-no-thanks-btn", config.wording.closeBtn, () => {
      analytics.onClose();
      popup?.remove();
      setStickyFooter("gf-sticky-footer", "gf-close-footer-btn", analytics, config);
    });
    setButton("gf-not-show-btn", config.wording.notShowBtn, () => {
      analytics.onPermanentClose();
      const stickyFooter = document.getElementById("gf-sticky-footer");
      dismissForever({ popup, stickyFooter });
    });
  },
  {
    countriesToShow: ["US", "CA", "GB", "UK"]
  }
);
