import { getAnalytics, type Analytics, type AnalyticsUrlsRecord } from "../services/analytics";
import { GF_FOOTER_NOT_SHOW } from "./constants";
import { getCookie } from "./cookies";
import { dismissFooter, dismissForever } from "./dismiss";
import { dayInMS, isCorrectPage, isFilterBypassed } from "./verifications";

export const setStickyFooter = (
  footerId: string,
  closeBtnId: string,
  analytics: Analytics,
  config?: Config
) => {
  analytics.onFooterLoad();
  const stickyFooter = document.getElementById(footerId);
  if (!stickyFooter) return;

  const bypassFilters = isFilterBypassed();
  const shouldShowByConfig = isCorrectPage(
    config?.stickyFooter?.shouldShowRegex ?? ""
  );
  const shouldNotShowByCookie = getCookie(GF_FOOTER_NOT_SHOW);

  if ((shouldNotShowByCookie || !shouldShowByConfig) && !bypassFilters) {
    stickyFooter.remove();
    return;
  }
  analytics.onFooterOpen();
  const container = document.getElementById("gf-sticky-footer-container");
  const title = document.getElementById("gf-sticky-footer-title");
  const copy = document.getElementById("gf-sticky-footer-copy");
  const cta = document.getElementById("gf-sticky-footer-cta");
  const todayBtn = document.getElementById("gf-sticky-footer-today");
  const foreverBtn = document.getElementById("gf-sticky-footer-forever");
  const feedbackBtn = document.getElementById("gf-sticky-footer-feedback");
  title && (title.innerHTML = config?.stickyFooter?.wording.title ?? "");
  copy && (copy.innerHTML = config?.stickyFooter?.wording.copy ?? "");
  cta && (cta.innerHTML = config?.stickyFooter?.wording.cta ?? "");
  todayBtn &&
    (todayBtn.innerHTML = config?.stickyFooter?.wording.closeTodayBtn ?? "");
  foreverBtn &&
    (foreverBtn.innerHTML =
      config?.stickyFooter?.wording.closeForeverBtn ?? "");
  feedbackBtn &&
    (feedbackBtn.innerHTML = config?.stickyFooter?.wording.feedbackBtn ?? "");

  const handleClickClose = () => {
    const stickyFooterButtons = document.getElementById(
      "gf-sticky-footer-buttons"
    );
    stickyFooterButtons && (stickyFooterButtons.style.display = "flex");
    todayBtn?.addEventListener("click", () => {
      analytics.onFooterClose();
      dismissFooter(stickyFooter, dayInMS);
    }
    );
    foreverBtn?.addEventListener("click", () => {
      analytics.onFooterPermanentClose()
      dismissForever({ stickyFooter })
    }
    );
    feedbackBtn?.addEventListener("click", () => {
      window.open("https://gvfr.ly/3QQKeb4", "_blank");
    });
    closeFooter?.addEventListener("click", () => {
      analytics.onFooterClose();
      dismissFooter(stickyFooter, dayInMS);
    }
    );
  };

  const closeFooter = document.getElementById(closeBtnId);
  stickyFooter.style.opacity = "1";
  closeFooter?.addEventListener("click", handleClickClose);

  window.addEventListener("scroll", () => {
    const isAtBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight;
    if (isAtBottom && stickyFooter) {
      stickyFooter.style.opacity = "0";
      container && (container.style.pointerEvents = "none");
    }
    if (!isAtBottom && stickyFooter) {
      stickyFooter.style.opacity = "1";
      container && (container.style.pointerEvents = "all");
    }
  });
}

/**
 * 
 *  @deprecated
 */
export const renderStickyFooter = (
  contents: string,
  footerId: string,
  closeBtnId: string,
  urls: AnalyticsUrlsRecord,
  config?: Config
) => {
  const bypassFilters = isFilterBypassed();
  const shouldShow = isCorrectPage(config?.stickyFooter?.shouldShowRegex ?? "");
  const analytics = getAnalytics(urls, "gutenberg");
  if ((getCookie(GF_FOOTER_NOT_SHOW) || !shouldShow) && !bypassFilters) return;
  const container = document.createElement("div");
  container.innerHTML = contents;
  document.body.appendChild(container);
  setStickyFooter(footerId, closeBtnId, analytics, config);
};
